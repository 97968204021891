

const initHighriseAutocomplete = (callback) => {
  $("#highrise_search").autocomplete({
    source: Routes.admin_highrise_people_search_path({format: 'json'}),
    minLength: JSConstant.HIGHRISEHQ_INPUT_MIN_LENGTH,
    select: ( event, ui ) => {
      var person = ui.item;
      callback(person);
      return false;
    }
  })
  .autocomplete( "instance" )._renderItem = ( ul, item ) => {
    let fullname = `${item.first_name} ${item.last_name}`;
    let fullnameText = [fullname, item.company_name].join('<br>')
    return $( "<li></li>" )
      .data( "item.autocomplete", item )
      .append(`<a>${fullnameText}</a>`)
      .appendTo( ul );
  };
}

const handleSubmitEmail = () => {
  $("#email_form").submit(() => {
    if ($("#highrise_id").val() == "") {
      alert("No highrise recipient found.");
      return false;
    }
  })
}

const searchHighriseNewEmailCallback = (person) => {
  var personUrl = `${JSConstant.HIGHRISEHQ_URL}/people/${person.id}`
  $( "#employer_name" ).html( [person.first_name, person.last_name].join(' ') );
  $( "#employer_email" ).html( person.contact_data.email_addresses[0].address );
  $( "#employer_company" ).html( person.company_name );
  $("#employer_highrise_link").html(`<a href=${personUrl} target="_blank">Click here</a>`);
  $("#highrise_id").val(person.id);
}

const searchHighriseEditEmployerCallback = (person) => {
  $("#employer_highrise_id").val(person.id);
}

const newEmailAutoComplete = () => {
  initHighriseAutocomplete(searchHighriseNewEmailCallback);
  handleSubmitEmail();
}

const editEmployerAutoComplete = () => {
  initHighriseAutocomplete(searchHighriseEditEmployerCallback);
}

const creditPurchasesValidation = () => {
  $('.new_publishing_credit_purchase').validate({
    rules: {
      'publishing_credit_purchase[credit_count]': {
        required: true,
        number: true
      }
    }
  })
}


window.AdminModule = {
  newEmailAutoComplete,
  editEmployerAutoComplete,
  creditPurchasesValidation
}

$(() => {
  AdminModule.creditPurchasesValidation();
})