$(() => {
  initModal = (options) => {
    if (!Object.keys(options).includes('text') || !Object.keys(options).includes('title')) {
      return
    }
    $('#dialog_modal').text(options.text)
    $('#dialog_modal').dialog({
      title: options.title,
      autoOpen: false,
      closeText: '\u2715',
      close: (event) => {
        $('#dialog_modal').text('')
      },
      buttons: options.buttons
    })
    $dialog = $(".ui-dialog");
    $dialog.addClass("modal-content");
    //$dialog.find(".ui-dialog-titlebar").addClass("modal-header").find(".ui-dialog-titlebar-close").addClass("ui-button ui-corner-all ui-widget ui-button-icon-only").text("x");
    //$dialog.find(".ui-dialog-content").addClass("modal-body");
    //$dialog.find(".ui-dialog-buttonpane").find(".ui-dialog-buttonset").find("button").addClass("ui-button ui-corner-all");
    $('#dialog_modal').dialog('open')
  }

  $('#logout-button').click(() => {
    initModal({
      title: 'Log out',
      text: 'Are you sure want to log out?',
      buttons: [
        {
          text: 'OK',
          click: () => {
            $.ajax({
              url: '/logout',
              method: 'delete',
              complete: (response) => {
                $('#dialog_modal').dialog('close')
                window.location.replace('/')
              }
            })
          }
        }
      ]
    })
  })

  $('#delete-listing').click((event) => {
    let listingId = $(event.target).data('id')
    initModal({
      title: 'Job Listing Delete',
      text: 'Are you sure you want to delete this listing? There is no undo.',
      buttons: [
        {
          text: 'OK',
          click: () => {
            $.ajax({
              url: `/listings/${listingId}`,
              method: 'delete',
              complete: (response) => {
                $('#dialog_modal').dialog('close')
                window.location.replace('/employer')
              }
            })
          }
        }
      ]
    })
  })

  $('#mark-spam-comment, #delete-comment').click((event) => {
    _this = event.target
    let blogId = $(_this).data('blog-id')
    let commentId = $(_this).data('id')
    let isMarkSpam = _this.id == 'comment-spam'
    let title = isMarkSpam ? 'Mark Comment As Spam' : "Destroy Comment"
    let text = isMarkSpam ? 'Are you sure you want to mark this comment as spam?' : "Are you sure you want to delete this comment?"
    initModal({
      title: title,
      text: text,
      buttons: [
        {
          text: 'OK',
          click: () => {
            $.ajax({
              url: `/blog/${blogId}/blog_comments/${commentId}`,
              data: {
                spam: isMarkSpam
              },
              method: 'delete',
              complete: (response) => {
                $('#dialog_modal').dialog('close')
                $(_this).closest('.comment').remove()
              }
            })
          }
        }
      ]
    })
  })
})
