const blogPostFormValidation = () => {
  $('.new_blog_post, .edit_blog_post').validate({
    rules: {
      'blog_post[title]': 'required'
    }
  })
}

const employerPageFromValidation = () => {
  $('.new_employer_page, .edit_employer_page').validate({
    rules: {
      'employer_page[title]': 'required'
    }
  })
}

const jobListingFormValidation = () => {
  $('.new_job_listing, .edit_job_listing').validate({
    rules: {
      'job_listing[title]': 'required',
      'job_listing[position]': 'required',
      'job_listing[employer]': 'required',
      'job_listing[practice_type]': 'required',
      'job_listing[description]': 'required',
      'job_listing[description]': 'required',
      'job_listing[subspecialty][]': 'required',
      'job_listing[youtube_url]': {
        youtube: '#job_listing_youtube_url',
        url: true
      }
    }
  })
}

const contactFormValidation = () => {
  $('.contact-form').validate({
    rules: {
      'name': 'required',
      'email': {
        required: true,
        email: true
      },
      'subject': 'required',
      'message': 'required'
    }
  })
}

const signInValidation = () => {
  $('.new_user_session').validate({
    rules: {
      'user_session[email]': {
        required: true,
        email: true
      },
      'user_session[password]': 'required'
    }
  })
}

const resetPasswordValidation = () => {
  $('.reset-password-form').validate({
    rules: {
      'email': {
        required: true,
        email: true
      }
    }
  })
}

const signUpValidation = () => {
  $('.new_user, .edit_user').validate({
    rules: {
      'user[first_name]': 'required',
      'user[last_name]': 'required',
      'user[email]': {
        email: true,
        required: true
      },
      'user[password]': {
        required: $('.new_user').length > 0
      },
      'user[password_confirmation]': {
        equalTo: '#user_password'
      },
      'user[terms_accepted]': {
        required: function(element) {
          return $("#user_type").val() == 'Employer';
        }
      },
      'user[job_seeker_profile_attributes][graduation_year]': {
        required: $('#user_job_seeker_profile_attributes_graduation_year').length >0,
        digits: true,
        minlength: 4,
        maxlength: 4,
        range:[1920, new Date().getFullYear()+6]
      }
    }
  })
}

const blogCommentValidation = () => {
  $('.new_blog_comment').validate({
    rules: {
      'blog_comment[name]': 'required',
      'blog_comment[email]': {
        required: true,
        email: true
      },
      'blog_comment[body]': 'required'
    }
  })
}

const start = () => {
  blogPostFormValidation();
}

const residnecyProgramSearchable = () => {
  document.addEventListener("DOMContentLoaded", () => {
    $(".residency_program").select2()
  })
}

const stateSearchable = () => {
  document.addEventListener("DOMContentLoaded", () => {
    $('.listing_state_practice').select2({
      placeholder: "Select all that apply"
    });
  })
}

const employerTermsAccepted = () => {
  document.addEventListener("DOMContentLoaded", () => {
    if($('.new_user').find('#user_type').length > 0 && $('.new_user').find('#user_type').val() == 'JobSeeker'){
      $('.employer_terms_accepted').css("display", "none");
    }
    $('.new_user #user_type').change(function(){
      if($(this).val() == 'JobSeeker'){
        $('.employer_terms_accepted').css("display", "none");
      }else{
        $('.employer_terms_accepted').css("display", "block");
      }
    })
  })
}

window.UJS = {
  start,
  blogPostFormValidation,
  employerPageFromValidation,
  jobListingFormValidation,
  contactFormValidation,
  signInValidation,
  resetPasswordValidation,
  signUpValidation,
  blogCommentValidation,
  residnecyProgramSearchable,
  stateSearchable,
  employerTermsAccepted
}

$(() => {
  UJS.start()
})